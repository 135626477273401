import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "405",
  height: "217",
  viewBox: "0 0 405 217",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M-171.003 360.059C-78.993 550.136 128.677 608.644 319.421 483.299C510.164 357.954 347.549 9.01885 159.697 0.318087C-28.1548 -8.38261 -260.095 176.021 -171.003 360.059Z",
      fill: "#FFF5BF"
    }, null, -1)
  ])))
}
export default { render: render }